import React from "react"
import { MainNav } from "../assemblies/nav"
import { PageContainer, PageFooter } from "../assemblies/pageTemplate"
import { Box } from "../layout/box"
import styled from "styled-components"
import { SubTitle, PlainLink } from "../elements/typography"
import { Switcher } from "../layout/switcher"
import { Stack } from "../layout/stack"
import { Center } from "../layout/center"

import heroImg from "./index-files/hero.jpg"
import fabricationImg from "./index-files/fabrication.svg"
import softwareImg from "./index-files/software.svg"
import circuitImg from "./index-files/circuit.svg"
import { PageTitle, PageMeta } from "../assemblies/pageHeader"
import { Scene, SceneTitle } from "../elements/scene"
import { Link } from "gatsby"

const HeroOverlay = styled.div`
  min-width: var(--s5);
  padding: var(--s1);

  position: absolute;
  bottom: 0;
  right: var(--s1);
  background-color: var(--color-light);

  @media (max-width: 768px) {
    position: relative;
    font-size: 75%;
    bottom: unset;
    right: unset;
    min-width: unset;
  }
`

const HeroDimmer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.3);
`

const HomeHero = () => (
  <Box style={{ position: "relative", textAlign: "right" }}>
    <HeroDimmer />
    <img src={heroImg} />
    <HeroOverlay>
      <PageTitle title="Specific Solutions Prototyping" />
      <SubTitle>solving problems so you don&apos;t have to</SubTitle>
      <PageMeta description="Specific Solutions LLC creates prototypes for your ideas" />
    </HeroOverlay>
  </Box>
)

const ColorBar = styled.div<{ color: string }>`
  height: var(--s-2);
  background-color: ${p => p.color};
`

const Service: React.FC<{ imgSrc: string; title: string }> = ({
  imgSrc,
  title,
  children,
}) => (
  <Center maxWidth="40ch">
    <Stack gap={0}>
      <img src={imgSrc} style={{ width: "20ch", margin: "0 auto" }} />
      <h2 style={{ textAlign: "center" }}>{title}</h2>
      {children}
    </Stack>
  </Center>
)

const ServicesOffered = () => (
  <Box>
    <ColorBar color="var(--color-primary)" />
    <ColorBar color="var(--color-secondary)" />
    <Scene>
      <SceneTitle>Capabilities</SceneTitle>
      <Switcher minWidth="60ch" gap={2} justify="center">
        <Service title="Fabrication" imgSrc={fabricationImg}>
          <ul>
            <li>
              <PlainLink to="/capability/3d-printing">3D Printing</PlainLink>
            </li>
            <li>
              <Link to="/capability/3d-printing">
                Metalworking - lathe, mill, welding
              </Link>
            </li>
            <li>Woodworking</li>
          </ul>
        </Service>
        <Service title="Software" imgSrc={softwareImg}>
          <ul>
            <li>Full stack web development</li>
            <li>Native and hybrid apps</li>
            <li>Dev-ops</li>
          </ul>
        </Service>
        <Service title="Electronics" imgSrc={circuitImg}>
          <ul>
            <li>Arduino development</li>
            <li>Bluetooth integration</li>
            <li>Mechatronics</li>
          </ul>
        </Service>
      </Switcher>
    </Scene>
  </Box>
)

const IndexPage = () => (
  <PageContainer>
    <MainNav className="wide" />
    <HomeHero />
    <ServicesOffered />
    <PageFooter />
  </PageContainer>
)
export default IndexPage
