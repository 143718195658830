import styled from "styled-components"
import { GUTTER } from "../layout/util"
import { Center } from "../layout/center"
import { padStyle } from "../layout/box"

export const Scene = styled(Center).attrs(() => ({
  centerAll: true,
  p: GUTTER,
}))`
  ${padStyle}
  min-height: 80vh;
`

export const SceneTitle = styled.h2`
  text-align: center;
`
